import React from "react";
import Artykul from "../components/artykul";
import {  graphql } from "gatsby";
import Img from "gatsby-image";
import obrazekArtykulu from "../images/jaki-crm-dla-agencji-reklamowej.jpg";
import Lightbox from "../components/lightBox";

export const query = graphql`
  query {
    zdjecie1: file(relativePath: { eq: "zarzadzanie-szansami-sprzedazy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(relativePath: { eq: "zmiana-etapu-szansy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
     zdjecie3: file(relativePath: { eq: "karta-szansy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie4: file(relativePath: { eq: "karta-kontrahenta-w-systemie-dla-agencji-reklamowej.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
     zdjecie5: file(relativePath: { eq: "zarzadzanie-projetkami-w-agencji-reklamowej.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
const Jakicrm = ({ data }) => {
  return (
    <Artykul
      title="Jaki CRM dla agencji reklamowej?"
      articleImage={obrazekArtykulu}
      keywords={["jaki crm dla agencji reklamowej"]}
      articleImageAlt="jaki system crm dla agencji reklamowej."
      metaTitle="Jaki CRM dla agencji reklamowej?"
      metaDescription="Wsparcie sprzedaży i realizacji projektów w jednym miejscu ✅ Wsparcie sprzedaży • Zarządzanie projektami • Sprawna komunikacja • Wypróbuj bezpłatne demo"
    >
      <br />
      <h2 style= {{
          textAlign: "left",
          marginBottom:20,
      }}>
        Wsparcie sprzedaży i realizacji projektów w jednym miejscu
      </h2>
      <p>
       Mikro i względnie małe <strong>agencje reklamowe, agencje interaktywne oraz firmy oferujące materiały reklamowe</strong>, organizują swoją pracę korzystając z podstawowych i łatwo dostępnych narzędzi, takich jak kalendarze, dysk i narzędzia google oraz popularne komunikatory. Dopełnieniem jest biuro księgowe, które panuje nad rozliczeniami. 
      </p>
<p>Mimo wszystko, <strong>coraz częściej już kilkuosobowe zespoły poszukują systemów typu CRM, do organizacji pracy i budowania relacji z klientami</strong>. Dzieje się tak wraz ze wzrostem skali i pojawiającymi się trudnościami w koordynacji pracy wielu osób oraz przetwarzaniem informacji z wielu różnych źródeł. Natłok zadań i danych, które przetwarzane są przez pracowników każdego dnia wymaga pracy ze wsparciem odpowiedniego narzędzia. Naturalne stało się poszukiwanie rozwiązań, szczególnie w momencie, gdy zespół działa zdalnie.</p>
<h2 style= {{
          textAlign: "left",
          marginBottom:40,
          marginTop:40
          }}><strong>Jaki system CRM do agencji reklamowej będzie odpowiedni?</strong></h2>

<p><strong>Im większy zespół tym potencjalnie więcej informacji może umykać Twojej firmie.</strong> Handlowiec zapomni zadzwonić do potencjalnego klienta, który następnie wybierze usługi konkurencji. Grafik zapomni jakie były ustalenia, więc zrealizuje zadanie po swojemu. </p>
 <p>Podobny problem wynikający z braku standardów przetwarzania informacji zauważysz w momencie odejścia pracownika. Nikt w firmie nie będzie wiedział z jakimi klientami i o czym rozmawiał, przez co tracisz szansę na efektywne domknięcie już rozpoczętych tematów.</p>
 <p>Agencja reklamowa może potrzebować wsparcia szczególnie w dwóch obszarach działalności - sprzedaży i zarządzania zleceniami (a nawet złożonymi projektami). 
</p>
<p><strong>Niezależnie od tego czy prowadzisz inbound czy outbound marketing, CRM dla agencji reklamowej jest w stanie usprawnić Twoją sprzedaż. </strong>
</p>
<h2 style= {{
          textAlign: "left",
           marginBottom:40,
           marginTop:40
      }}><strong>Przetwarzanie informacji o szansie sprzedaży</strong></h2>
     <p>Kampanie sprzedażowe w systemie CRM pozwalają gromadzić informacje o potencjalnych transakcjach w jednym miejscu. Kampania składa się z kilku etapów, które możesz dostosować do potrzeb własnego biznesu (zmieniając ich liczbę i nazewnictwo). Na kanbanie umiejscowione są “szanse sprzedaży”,które zawierają wszystkie informacje o potencjalnej sprzedaży. 
</p>
       <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie1]}
        alts={[
          "Zarządzanie sprzedażą w systemie dla agencji reklamowej",
        ]}
      />  
     <p>Wraz z osiągnięciem kolejnych kroków przez handlowca, szansa przesuwa się po kampanii sprzedażowej, co pozwala kierownikowi na szybkie zweryfikowanie na jakim etapie rozmów z danym klientem jest obecnie sprzedawca (bez poświęcania czasu na szczegóły). </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie2]}
        alts={[
          "Zmiana etapu szansy sprzedaży w crm-ie dla agencji reklamowej",
        ]}
      /> 
      <p>
      <ol>
      <p>W karcie szansy sprzedaży znajdują m.in.:
<li>informacje o źródle pozyskania,</li>
<li>notatki ze wszystkich rozmów z tym klientem,</li>
<li>zaplanowane kolejne zadania (w systemie możesz prowadzić kalendarz, zintegrowany z kalendarzem w Twoim telefonie),</li>
<li>cele, które handlowiec powinien zrealizować na danym etapie z klientem (np. “doprecyzowanie potrzeb”),</li>
<li>podpięte dokumenty np. oferty handlowe,</li>
<li>atrybuty wynikające ze specyfiki Twojego biznesu - możesz samodzielnie dodawać pola, które będą widoczne w każdej szansie sprzedaży.</li>
</p>
      </ol>
      </p> 
        <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie3]}
        alts={[
          "Informacje o potencjalnej sprzedaży",
        ]}
      /> 
      <br />
      <p><strong>Jeżeli prowadzisz sprzedaż produktów i/lub usług, a sposób pozyskiwania klienta w obu przypadkach jest różny to warto oddzielić te szanse od siebie i prowadzić je w osobnych kampaniach, co znacznie uprości analizę danych. </strong>
</p>
<p>System CRM dla agencji reklamowej to kopalnia wiedzy o potencjalnych i obecnych klientach. Kampanie sprzedażowe to także zestaw raportów, który prezentuje takie informacje jak:
<ol>
<li>napływ szans w danych kampaniach,</li>
<li>liczba i wartość szans na każdym etapie kampanii sprzedażowej,</li>
<li>aktywność handlowców - jakie typy zadań wykonali (ile spotkań, ile przesłanych ofert itp.),</li>
<li>konwersję lejka pomiędzy etapami w kampanii (możesz szybko zweryfikować, gdzie występuje wąskie gardło i szukać informacji o jego przyczynach w kolejnych raportach),</li>
<li>przyczyny utraty szans sprzedaży (notatki pracowników, pogrupowane i wyjaśniające, dlaczego straciliście szansę na nowego klienta),</li>
<li>i inne: sprawdź możliwości :)</li>
</ol></p>
<h3 style= {{
          textAlign: "left",
           marginBottom:40,
           marginTop:40
      }}><strong>Co zyskujesz?</strong></h3>
<p>
<ol>
<li>uporządkowany proces sprzedaży, </li>
<li>spójny standard obsługi potencjalnych klientów,</li>
<li>oszczędność czasu - jedno, proste narzędzie pracy dla handlowców,</li>
<li>jedno miejsce przechowywania informacji o potencjalnych klientach,</li>
<li>źródło wiedzy o przyczynach utraty szans sprzedaży,</li>
<li>bezpieczeństwo - tylko uprawnione osoby mają dostęp do informacji o kontrahentach.</li>

</ol>
</p>
<h2 style= {{
          textAlign: "left",
           marginBottom:40,
           marginTop:40
      }}><strong>Przetwarzanie informacji o potencjalnych i aktualnych klientach</strong></h2>
<p>W systemie przechowywane są dane o kontrahentach. Część danych jest tożsama dla wielu firm (NIP, adres itp.).<strong> Inne, zgodne ze specyfiką Twojego biznesu możesz dodać samodzielnie</strong>. Dodatkowe atrybuty możliwe do wyświetlenia w karcie kontrahenta umożliwiają segmentacje klientów, a następnie sprawne wyszukiwanie lub pobranie adresów e-mail i wykorzystanie ich w mailingu. 
</p>
<p>Przykładowe atrybuty w karcie kontrahenta to:
<ol>
<li>branża,</li>
<li>skala,</li>
<li>status relacji,</li>
<li>pole opisowe.</li>
</ol><strong>To Ty decydujesz, jakie dane są dla Ciebie niezbędne do przechowywania.</strong>
</p>
<p>W karcie kontrahenta znajdują się także wszystkie:
<ol>
<li>notatki z nim powiązane, </li>
<li>lista zaplanowanych zadań (np. kontaktów), </li>
<li>zrealizowane zlecenia dla klienta, </li>
<li>powiązane dokumenty (zamówienia, oferty, faktury). </li>
</ol>
</p>
    <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie4]}
        alts={[
          "Karta kontrahenta w systemie dla agencji reklamowej",
        ]}
      /> 
<h3 style= {{
          textAlign: "left",
           marginBottom:40,
           marginTop:40
      }}><strong>Co zyskasz przechowując te dane w systemie? </strong></h3>
      <p><ol>
<li>dane nie zostaną zgubione,</li>
<li>tylko uprawnione osoby będą mieć do nich dostęp,</li>
<li>sprawną zastępowalność np. pracownika przebywającego na urlopie, </li>
<li>porządek w historii współpracy z klientami,</li>
<li>spójny standard obsługi klientów i przechowywania danych.</li>

</ol></p>

<h2 style= {{
          textAlign: "left",
           marginBottom:40,
           marginTop:40
      }}><strong>Zarządzanie zleceniami i projektami</strong></h2>
<p>Empireo umożliwia także zarządzanie zleceniami za pomocą wygodnych tablic kanban. Zlecenia mogą zawierać dowolne atrybuty (np. opisowe, listy), co pozwala na dopasowanie tego obszaru pod specyfikę działania konkretnej agencji. 
</p>
<p>Empireo porządkuje przepływ zleceń, dzięki tablicom <strong>Kanban</strong>. Oprócz tego system dla agencji reklamowej pozwala zarządzać projektami na <strong>wykresie Gantta</strong>.
</p>
<Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie5]}
        alts={[
          "Zarządzanie projektami w agencji reklamowej",
        ]}
      /> 

<p>Co ważne, w systemie można tworzyć wiele tablic, dla każdego zespołu osobno. 
</p>
<p><strong>W karcie każdego zlecenia znajdują się także zaplanowane zadania, które są wyświetlone również na kalendarzu. Zadania mogą być zintegrowane z kalendarzem na telefonie. </strong>
</p>
<p>Oprócz tego, widoczne są wszystkie zdarzenia (wykonane zadania) wraz z notatkami. Ta cała historia zleceń i zadań znajduje się także w karcie kontrahenta. W ten sposób historia współpracy jest łatwa do odszukania. 
</p>
<p><strong>System zlicza godziny poświęcone na zlecenie, na podstawie informacji w zdarzeniu, co usprawnia sposób rozliczania z pracownikami.</strong>
</p>
<h2 style= {{
          textAlign: "left",
           marginBottom:40,
           marginTop:40
      }}><strong>Wbudowany komunikator</strong></h2>

    <p>Wiemy, jak ważny jest przepływ informacji w firmie, dlatego systemie dostępny jest komunikator, który umożliwia wysyłanie wiadomości indywidualnych, grupowych oraz przeszukiwanie wątków. <strong>Wiadomości mogą być wiązane z konkretnym zleceniem czy szansą sprzedaży, a jednocześnie łatwo do nich dotrzeć przez stałą ikonkę w menu.</strong> </p>
    <p>Główną zaletą jest to, że <strong>wszystkie ustalenia dotyczące sprzedaży, projektów czy klientów znajdują się w systemie i nie można ich usunąć</strong>. W ten sposób mamy pewność, że cała historia komunikacji nie zniknie i zawsze można wrócić do potrzebnych informacji.
</p>
<h2 style= {{
          textAlign: "left",
           marginBottom:40,
           marginTop:40
      }}><strong>Jeden system = wiele możliwości</strong></h2>
      <p>
<strong>Dla właściciela agencji zarządzanie firmą, nadzorowanie sprzedaży i projektów w jednym systemie to znaczna oszczędność czasu i pieniędzy.</strong> Dzięki tablicom kanban szybko zorientujesz się w sytuacji działu sprzedaży czy realizacji zleceń, a zestaw raportów pozwoli Ci być na bieżąco z kluczowymi wskaźnikami. 
</p>
<p>CRM dla agencji marketingowej <strong>porządkuje kluczowe procesy, dostarcza niezbędnych informacji w odpowiednim momencie, jest łącznikiem między działami - dba o komunikację</strong>. 
</p>
<p>Dla właściciela agencji system stanowi centrum dowodzenia. Oszczędza mnóstwo czasu i umożliwia stabilny rozwój w oparciu o realne dane.</p>
<p>Jeden system zamiast wielu narzędzi to <strong>bezpieczeństwo przechowywania informacji i wygoda</strong>. Jego znaczenie docenia się także w przypadku nagłej potrzeby zastąpienia pracownika - wszystkie informacje o kliencie będą pod ręką.
</p>
<p>Empireo to system CRM dla agencji reklamowej i marketingowej, który możecie wypróbować przez 30 dni za darmo. W tym czasie możemy pomóc Wam dostosować jego możliwości do potrzeb Waszej firmy. 
</p>
<p><strong><a href="/demo/">Kliknij tutaj, aby wypróbować bezpłatnie Empireo.</a></strong></p> 
    </Artykul>
  );
};

export default Jakicrm;
